import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, Inject, ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, ViewChild, Input, HostBinding, HostListener, NgModule } from '@angular/core';
import * as i8 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { maskitoDateTimeOptionsGenerator } from '@maskito/kit';
import * as i7 from '@taiga-ui/cdk';
import { TuiDay, TuiTime, AbstractTuiControl, ALWAYS_FALSE_HANDLER, TuiMonth, changeDateSeparator, DATE_FILLER_LENGTH, tuiDateClamp, tuiNullableSame, tuiClamp, TUI_DATE_FORMAT, TUI_DATE_SEPARATOR, TUI_IS_MOBILE, TUI_IS_IOS, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure, TuiPreventDefaultModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_TEXTFIELD_HOST, TUI_TEXTFIELD_SIZE, TuiPrimitiveTextfieldComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiWrapperModule, TuiCalendarModule, TuiSvgModule, TuiLinkModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiTextfieldComponent } from '@taiga-ui/core';
import { DATE_TIME_SEPARATOR, TUI_DATE_MODE_MASKITO_ADAPTER } from '@taiga-ui/kit/constants';
import { TUI_TIME_TEXTS, TUI_DATE_TEXTS, TUI_DATE_TIME_VALUE_TRANSFORMER, TUI_INPUT_DATE_OPTIONS, tuiDateStreamWithTransformer } from '@taiga-ui/kit/tokens';
import * as i9 from 'rxjs';
import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as i2 from '@taiga-ui/kit/directives';
import { TuiValueAccessorModule } from '@taiga-ui/kit/directives';
import * as i3 from '@maskito/angular';
import { MaskitoModule } from '@maskito/angular';
import * as i4 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
const _c2 = a0 => ({
  $implicit: a0
});
function TuiInputDateTimeComponent_ng_template_5_tui_svg_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 7);
  }
  if (rf & 2) {
    const src_r2 = ctx.polymorpheusOutlet;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("t-icon", !ctx_r2.computedDisabled);
    i0.ɵɵproperty("src", src_r2);
  }
}
function TuiInputDateTimeComponent_ng_template_5_input_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 8);
  }
}
function TuiInputDateTimeComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TuiInputDateTimeComponent_ng_template_5_tui_svg_0_Template, 1, 3, "tui-svg", 5)(1, TuiInputDateTimeComponent_ng_template_5_input_1_Template, 1, 0, "input", 6);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.calendarIcon)("polymorpheusOutletContext", i0.ɵɵpureFunction1(3, _c2, ctx_r2.size));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.showNativePicker);
  }
}
function TuiInputDateTimeComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-calendar", 9);
    i0.ɵɵlistener("dayClick", function TuiInputDateTimeComponent_ng_template_7_Template_tui_calendar_dayClick_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onDayClick($event));
    })("monthChange", function TuiInputDateTimeComponent_ng_template_7_Template_tui_calendar_monthChange_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onMonthChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabledItemHandler", ctx_r2.disabledItemHandler)("max", ctx_r2.calendarMaxDay)("min", ctx_r2.calendarMinDay)("month", ctx_r2.computedActiveYearMonth)("value", ctx_r2.calendarValue);
  }
}
class TuiNativeDateTimeDirective {
  constructor(host) {
    this.host = host;
  }
  get value() {
    var _a, _b;
    if (!this.host.rawValue[0] || !this.host.rawValue[1]) {
      return '';
    }
    return `${(_a = this.host.rawValue[0]) === null || _a === void 0 ? void 0 : _a.toString('YMD', '-')}T${(_b = this.host.rawValue[1]) === null || _b === void 0 ? void 0 : _b.toString('HH:MM')}`;
  }
  onChange(value) {
    if (!value) {
      this.host.writeValue([null, null]);
      return;
    }
    const date = new Date(value);
    const day = TuiDay.fromLocalNativeDate(date);
    const time = TuiTime.fromLocalNativeDate(date);
    this.host.writeValue([day, time]);
  }
}
TuiNativeDateTimeDirective.ɵfac = function TuiNativeDateTimeDirective_Factory(t) {
  return new (t || TuiNativeDateTimeDirective)(i0.ɵɵdirectiveInject(TUI_TEXTFIELD_HOST));
};
TuiNativeDateTimeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiNativeDateTimeDirective,
  selectors: [["input", "tuiDateTime", ""]],
  hostAttrs: ["type", "datetime-local"],
  hostVars: 2,
  hostBindings: function TuiNativeDateTimeDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change.stop", function TuiNativeDateTimeDirective_change_stop_HostBindingHandler($event) {
        return ctx.onChange($event.target.value);
      })("input.stop", function TuiNativeDateTimeDirective_input_stop_HostBindingHandler($event) {
        return ctx.onChange($event.target.value);
      })("click.stop.silent", function TuiNativeDateTimeDirective_click_stop_silent_HostBindingHandler() {
        return 0;
      })("mousedown.stop.silent", function TuiNativeDateTimeDirective_mousedown_stop_silent_HostBindingHandler() {
        return 0;
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("tabIndex", -1)("value", ctx.value);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeDateTimeDirective, [{
    type: Directive,
    args: [{
      selector: 'input[tuiDateTime]',
      host: {
        type: 'datetime-local',
        '[tabIndex]': '-1',
        '[value]': 'value',
        '(change.stop)': 'onChange($event.target.value)',
        '(input.stop)': 'onChange($event.target.value)',
        '(click.stop.silent)': '0',
        '(mousedown.stop.silent)': '0'
      }
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_HOST]
      }]
    }];
  }, null);
})();
class TuiInputDateTimeComponent extends AbstractTuiControl {
  constructor(control, cdr, dateFormat, dateSeparator, timeTexts$, dateTexts$, valueTransformer, options, isMobile, isIos, textfieldSize) {
    super(control, cdr, valueTransformer);
    this.dateFormat = dateFormat;
    this.dateSeparator = dateSeparator;
    this.timeTexts$ = timeTexts$;
    this.dateTexts$ = dateTexts$;
    this.valueTransformer = valueTransformer;
    this.options = options;
    this.isMobile = isMobile;
    this.isIos = isIos;
    this.textfieldSize = textfieldSize;
    this.month = null;
    this.timeMode$ = new BehaviorSubject('HH:MM');
    this.min = this.options.min;
    this.max = this.options.max;
    this.disabledItemHandler = ALWAYS_FALSE_HANDLER;
    this.defaultActiveYearMonth = TuiMonth.currentLocal();
    this.open = false;
    this.filler$ = combineLatest([this.dateTexts$.pipe(map(dateTexts => changeDateSeparator(dateTexts[this.dateFormat], this.dateSeparator))), this.timeTexts$, this.timeMode$]).pipe(map(([dateFiller, timeTexts, timeMode]) => this.getDateTimeString(dateFiller, timeTexts[timeMode])));
  }
  set timeMode(value) {
    this.timeMode$.next(value);
  }
  get timeMode() {
    return this.timeMode$.value;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get computedMin() {
    return this.toTuiDay(this.min, this.options.min);
  }
  get computedMax() {
    return this.toTuiDay(this.max, this.options.max);
  }
  get fillerLength() {
    return DATE_FILLER_LENGTH + DATE_TIME_SEPARATOR.length + this.timeMode.length;
  }
  get maskOptions() {
    return this.calculateMask(this.computedMin, this.computedMax, this.timeMode, this.dateFormat, this.dateSeparator);
  }
  get nativeFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.textfield) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) !== null && _b !== void 0 ? _b : null;
  }
  get focused() {
    var _a;
    return !!((_a = this.textfield) === null || _a === void 0 ? void 0 : _a.focused);
  }
  get calendarIcon() {
    return this.options.icon;
  }
  get nativePicker() {
    return this.options.nativePicker && this.isMobile;
  }
  get showNativePicker() {
    return this.nativePicker && this.timeMode === 'HH:MM';
  }
  get computedValue() {
    const {
      value,
      nativeValue,
      timeMode
    } = this;
    const [date, time] = value;
    const hasTimeInputChars = nativeValue.length > DATE_FILLER_LENGTH;
    if (!date || !time && hasTimeInputChars) {
      return nativeValue;
    }
    return this.getDateTimeString(date, time, timeMode);
  }
  get calendarValue() {
    return this.value[0];
  }
  get calendarMinDay() {
    const min = this.computedMin;
    return Array.isArray(min) ? min[0] : min;
  }
  get calendarMaxDay() {
    const max = this.computedMax;
    return Array.isArray(max) ? max[0] : max;
  }
  get computedActiveYearMonth() {
    const {
      computedMin,
      computedMax
    } = this;
    return this.month || this.value[0] || tuiDateClamp(this.defaultActiveYearMonth, Array.isArray(computedMin) ? computedMin[0] : computedMin, Array.isArray(computedMax) ? computedMax[0] : computedMax);
  }
  get nativeValue() {
    var _a;
    return ((_a = this.nativeFocusableElement) === null || _a === void 0 ? void 0 : _a.value) || '';
  }
  set nativeValue(value) {
    if (!this.nativeFocusableElement) {
      return;
    }
    this.nativeFocusableElement.value = value;
  }
  onClick() {
    this.open = !this.open;
  }
  onValueChange(value) {
    if (!value) {
      this.onOpenChange(true);
    }
    if (value.length < DATE_FILLER_LENGTH) {
      this.value = [null, null];
      return;
    }
    const [date, time] = value.split(DATE_TIME_SEPARATOR);
    const parsedDate = TuiDay.normalizeParse(date, this.dateFormat);
    const parsedTime = time && time.length === this.timeMode.length ? TuiTime.fromString(time) : null;
    this.open = false;
    this.value = [parsedDate, parsedTime];
  }
  onDayClick(day) {
    var _a;
    const modifiedTime = this.value[1] && this.clampTime(this.value[1], day);
    const newCaretIndex = DATE_FILLER_LENGTH + DATE_TIME_SEPARATOR.length;
    this.value = [day, modifiedTime];
    this.updateNativeValue(day);
    (_a = this.nativeFocusableElement) === null || _a === void 0 ? void 0 : _a.setSelectionRange(newCaretIndex, newCaretIndex);
    this.open = false;
  }
  onMonthChange(month) {
    this.month = month;
  }
  onOpenChange(open) {
    this.open = open;
  }
  onFocused(focused) {
    this.updateFocused(focused);
    if (focused) {
      return;
    }
    timer(0).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.nativeValue = this.trimTrailingSeparator(this.nativeValue);
    });
    if (this.value[0] === null || this.value[1] !== null || this.nativeValue.length === this.fillerLength || this.timeMode === 'HH:MM') {
      return;
    }
    const [, time] = this.nativeValue.split(DATE_TIME_SEPARATOR);
    if (!time) {
      return;
    }
    const parsedTime = TuiTime.fromString(time);
    this.value = [this.value[0], parsedTime];
  }
  setDisabledState() {
    super.setDisabledState();
    this.open = false;
  }
  writeValue(value) {
    super.writeValue(value);
    this.nativeValue = this.value && (this.value[0] || this.value[1]) ? this.computedValue : '';
  }
  getFallbackValue() {
    return [null, null];
  }
  valueIdenticalComparator(oldValue, newValue) {
    return tuiNullableSame(oldValue[0], newValue[0], (a, b) => a.daySame(b)) && tuiNullableSame(oldValue[1], newValue[1], (a, b) => String(a) === String(b));
  }
  calculateMask(min, max, timeMode, dateFormat, dateSeparator) {
    return maskitoDateTimeOptionsGenerator({
      timeMode,
      dateSeparator,
      dateMode: TUI_DATE_MODE_MASKITO_ADAPTER[dateFormat],
      min: this.toNativeDate(min),
      max: this.toNativeDate(max)
    });
  }
  getDateTimeString(date, time, timeMode = 'HH:MM') {
    const dateString = date instanceof TuiDay ? date.toString(this.dateFormat, this.dateSeparator) : date;
    const timeString = time instanceof TuiTime ? time.toString(timeMode) : time || '';
    return timeString ? `${dateString}${DATE_TIME_SEPARATOR}${timeString}` : dateString;
  }
  updateNativeValue(day) {
    const time = this.nativeValue.split(DATE_TIME_SEPARATOR)[1] || '';
    this.nativeValue = this.getDateTimeString(day, time);
  }
  clampTime(time, day) {
    const {
      computedMin,
      computedMax
    } = this;
    const ms = time.toAbsoluteMilliseconds();
    const min = Array.isArray(computedMin) && day.daySame(this.calendarMinDay) ? computedMin[1].toAbsoluteMilliseconds() : -Infinity;
    const max = Array.isArray(computedMax) && day.daySame(this.calendarMaxDay) ? computedMax[1].toAbsoluteMilliseconds() : Infinity;
    return TuiTime.fromAbsoluteMilliseconds(tuiClamp(ms, min, max));
  }
  trimTrailingSeparator(value) {
    return value.replace(new RegExp(`(\\${this.dateSeparator}|${DATE_TIME_SEPARATOR}|\\.)$`), '');
  }
  toNativeDate(value) {
    if (!Array.isArray(value)) {
      return value.toLocalNativeDate();
    }
    const [{
      year,
      month,
      day
    }, {
      hours,
      minutes,
      seconds,
      ms
    }] = value;
    return new Date(year, month, day, hours, minutes, seconds, ms);
  }
  toTuiDay(value, fallback) {
    if (!value) {
      return fallback;
    }
    if (!Array.isArray(value)) {
      return value;
    }
    const [tuiDay, tuiTime] = value;
    if (!tuiDay) {
      return fallback;
    }
    if (!tuiTime) {
      return tuiDay;
    }
    return value;
  }
}
TuiInputDateTimeComponent.ɵfac = function TuiInputDateTimeComponent_Factory(t) {
  return new (t || TuiInputDateTimeComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_DATE_FORMAT), i0.ɵɵdirectiveInject(TUI_DATE_SEPARATOR), i0.ɵɵdirectiveInject(TUI_TIME_TEXTS), i0.ɵɵdirectiveInject(TUI_DATE_TEXTS), i0.ɵɵdirectiveInject(TUI_DATE_TIME_VALUE_TRANSFORMER, 8), i0.ɵɵdirectiveInject(TUI_INPUT_DATE_OPTIONS), i0.ɵɵdirectiveInject(TUI_IS_MOBILE), i0.ɵɵdirectiveInject(TUI_IS_IOS), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE));
};
TuiInputDateTimeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputDateTimeComponent,
  selectors: [["tui-input-date-time"]],
  viewQuery: function TuiInputDateTimeComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiInputDateTimeComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function TuiInputDateTimeComponent_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    min: "min",
    max: "max",
    disabledItemHandler: "disabledItemHandler",
    defaultActiveYearMonth: "defaultActiveYearMonth",
    timeMode: "timeMode"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputDateTimeComponent), tuiAsControl(TuiInputDateTimeComponent), tuiDateStreamWithTransformer(TUI_DATE_TIME_VALUE_TRANSFORMER)]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 9,
  vars: 16,
  consts: [["iconContent", ""], ["dropdown", "polymorpheus"], [1, "t-hosted", 3, "openChange", "canOpen", "content", "open"], ["automation-id", "tui-input-date-time__textfield", "tuiValueAccessor", "", 1, "t-textfield", 3, "focusedChange", "valueChange", "disabled", "invalid", "maskito", "nativeId", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldFiller", "tuiTextfieldIcon", "value"], [3, "polymorpheus"], ["appearance", "icon", "tuiWrapper", "", 3, "t-icon", "src", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["tuiDateTime", "", "class", "t-native-input", 4, "ngIf"], ["appearance", "icon", "tuiWrapper", "", 3, "src"], ["tuiDateTime", "", 1, "t-native-input"], ["automation-id", "tui-input-date-time__calendar", "tuiPreventDefault", "mousedown", 3, "dayClick", "monthChange", "disabledItemHandler", "max", "min", "month", "value"]],
  template: function TuiInputDateTimeComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "tui-hosted-dropdown", 2);
      i0.ɵɵlistener("openChange", function TuiInputDateTimeComponent_Template_tui_hosted_dropdown_openChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onOpenChange($event));
      });
      i0.ɵɵelementStart(1, "tui-primitive-textfield", 3);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵlistener("focusedChange", function TuiInputDateTimeComponent_Template_tui_primitive_textfield_focusedChange_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocused($event));
      })("valueChange", function TuiInputDateTimeComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onValueChange($event));
      });
      i0.ɵɵprojection(3);
      i0.ɵɵprojection(4, 1, ["ngProjectAs", "input", 5, ["input"]]);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(5, TuiInputDateTimeComponent_ng_template_5_Template, 2, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(7, TuiInputDateTimeComponent_ng_template_7_Template, 1, 5, "ng-template", 4, 1, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const iconContent_r5 = i0.ɵɵreference(6);
      const dropdown_r6 = i0.ɵɵreference(8);
      i0.ɵɵproperty("canOpen", ctx.interactive && !ctx.showNativePicker)("content", dropdown_r6)("open", ctx.open && ctx.interactive);
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("invalid", ctx.computedInvalid)("maskito", ctx.maskOptions)("nativeId", ctx.nativeId)("pseudoFocus", ctx.pseudoFocus)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("tuiTextfieldFiller", i0.ɵɵpipeBind1(2, 14, ctx.filler$) || "")("tuiTextfieldIcon", ctx.calendarIcon && iconContent_r5)("value", ctx.computedValue);
      i0.ɵɵadvance(6);
      i0.ɵɵproperty("polymorpheus", ctx.type);
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i1.TuiPrimitiveTextfieldComponent, i1.TuiSvgComponent, i1.TuiCalendarComponent, i1.TuiPrimitiveTextfieldDirective, i2.TuiValueAccessorDirective, i3.MaskitoDirective, i1.TuiTextfieldFillerDirective, i1.TuiTextfieldIconDirective, i4.PolymorpheusOutletDirective, i1.TuiWrapperDirective, i5.NgIf, TuiNativeDateTimeDirective, i4.PolymorpheusTemplate, i7.TuiPreventDefaultDirective, i5.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}.t-icon[_ngcontent-%COMP%]{pointer-events:auto}.t-button[_ngcontent-%COMP%]{display:flex;height:2.75rem;justify-content:center;box-shadow:inset 0 1px var(--tui-base-03)}.t-native-input[_ngcontent-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;pointer-events:auto;font-size:2rem}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiInputDateTimeComponent.prototype, "calculateMask", null);
__decorate([tuiPure], TuiInputDateTimeComponent.prototype, "getDateTimeString", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateTimeComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-date-time',
      templateUrl: './input-date-time.template.html',
      styleUrls: ['./input-date-time.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputDateTimeComponent), tuiAsControl(TuiInputDateTimeComponent), tuiDateStreamWithTransformer(TUI_DATE_TIME_VALUE_TRANSFORMER)]
    }]
  }], function () {
    return [{
      type: i8.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_FORMAT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_SEPARATOR]
      }]
    }, {
      type: i9.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_TIME_TEXTS]
      }]
    }, {
      type: i9.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_TEXTS]
      }]
    }, {
      type: i7.AbstractTuiValueTransformer,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_DATE_TIME_VALUE_TRANSFORMER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_INPUT_DATE_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_IOS]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }];
  }, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    disabledItemHandler: [{
      type: Input
    }],
    defaultActiveYearMonth: [{
      type: Input
    }],
    timeMode: [{
      type: Input
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }],
    calculateMask: [],
    getDateTimeString: []
  });
})();
class TuiInputDateTimeDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.computedValue;
  }
  get rawValue() {
    return this.host.value;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  writeValue(value) {
    this.host.writeValue(value);
  }
  process(input) {
    input.inputMode = 'numeric';
  }
}
TuiInputDateTimeDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiInputDateTimeDirective_BaseFactory;
  return function TuiInputDateTimeDirective_Factory(t) {
    return (ɵTuiInputDateTimeDirective_BaseFactory || (ɵTuiInputDateTimeDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputDateTimeDirective)))(t || TuiInputDateTimeDirective);
  };
})();
TuiInputDateTimeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiInputDateTimeDirective,
  selectors: [["tui-input-date-time"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputDateTimeDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateTimeDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input-date-time',
      providers: [tuiAsTextfieldHost(TuiInputDateTimeDirective)]
    }]
  }], null, null);
})();
class TuiInputDateTimeModule {}
TuiInputDateTimeModule.ɵfac = function TuiInputDateTimeModule_Factory(t) {
  return new (t || TuiInputDateTimeModule)();
};
TuiInputDateTimeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputDateTimeModule
});
TuiInputDateTimeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, MaskitoModule, PolymorpheusModule, TuiWrapperModule, TuiPreventDefaultModule, TuiCalendarModule, TuiSvgModule, TuiLinkModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiValueAccessorModule, TuiTextfieldControllerModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateTimeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MaskitoModule, PolymorpheusModule, TuiWrapperModule, TuiPreventDefaultModule, TuiCalendarModule, TuiSvgModule, TuiLinkModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiValueAccessorModule, TuiTextfieldControllerModule],
      declarations: [TuiInputDateTimeComponent, TuiInputDateTimeDirective, TuiNativeDateTimeDirective],
      exports: [TuiInputDateTimeComponent, TuiInputDateTimeDirective, TuiTextfieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiInputDateTimeComponent, TuiInputDateTimeDirective, TuiInputDateTimeModule };
